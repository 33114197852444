import Starsign1 from '../Portfolio_Pictures/StarsignDark.jpg'
import Starsign2 from '../Portfolio_Pictures/MiniatureStarsign.jpg'

const Starsign = () => {
    return ( 
        <div className="about">
            <div className="aboutContent">
                <section className='contentBlock'>
            <div className="opaquebox">
            Starsign was our prototype for our first project-based course, Foundations of IT Product Design. 
            The assignment was to create a lamp that could be used in special setting. 
            Using capacitive touch, we made a lamp that could be controlled through a miniature version of it.
            </div>
            <img src={Starsign1} alt="Starsign in the Dark"/>
            </section>
            <section className='contentBlock'>
            <img src={Starsign2} alt="The controlling part of the lamp - a miniature version of it with capacative touch." />
            <div className="opaquebox">
            Each part of the smaller star would correspond to the actual lamp, so the users could decide where they wanted to light up the lamp. 
            The smaller star would also light up to indicate that it was on.
            </div>
            </section>
        </div>
        </div>
     );
}
 
export default Starsign;