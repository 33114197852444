import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const DropdownMenu = () => {
  return (
    <div className="dropdown-menu">
      <ul>
      <div className="links">
        <li><Link to="/portfolio/starsign">Starsign</Link></li>
        <li><Link to="/portfolio/nuclearreactor">Nuclear Reactor</Link></li>
        <li><Link to="/portfolio/ecosphere">EcoSphere</Link></li>
        <li><Link to="/portfolio/vrairhockey">VR Air Hockey</Link></li>
        </div>
      </ul>
    </div>
  );
};

export default DropdownMenu;