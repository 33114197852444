import { Link } from "react-router-dom/cjs/react-router-dom.min";
import React, { useState } from 'react';

const HoverLinkItem = ({imageUrl, linkUrl, text}) => {
    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
      setHovered(true);
    };
  
    const handleMouseLeave = () => {
      setHovered(false);
    };
    return(
        <Link to={linkUrl} className={`image-link ${hovered ? 'hovered' : ''}`}
         onMouseEnter={handleMouseEnter} 
         onMouseLeave={handleMouseLeave}
         style={{marginRight:'100px'}}>
        <img src={imageUrl} alt={text} className="portfolioImg"/>
        <div className="overlay">
          <p className={`text ${hovered ? 'hidden' : ''}`}>{text}</p>
        </div>
      </Link>
      
    )

}
 
export default HoverLinkItem;