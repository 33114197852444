import EcoSphere1 from '../Portfolio_Pictures/EcoSphere.jpg'
import Bachelor3 from '../Portfolio_Pictures/doublePOV.jpg'
import Nuclear1 from '../Portfolio_Pictures/ToyNuclear.jpg'
import Starsign1 from '../Portfolio_Pictures/StarsignDark.jpg'
import HoverLinkItem from '../HoverLinkItem';

const Portfolio = () => {
    return (
        <div className="about">
            <div className='portContent'>
                <section className='contentBlock'>
                    <div className='image-row'>
                <HoverLinkItem 
                imageUrl={Starsign1}
                linkUrl="/portfolio/starsign"
                text="Starsign"
                />
                
                <HoverLinkItem
                imageUrl={Nuclear1}
                linkUrl="/portfolio/nuclearreactor"
                text="Nuclear Reactor"         
                />

                <HoverLinkItem
                imageUrl={EcoSphere1}
                linkUrl="/portfolio/ecosphere"
                text="EcoSphere"         
                />

                <HoverLinkItem
                imageUrl={Bachelor3}
                linkUrl="/portfolio/vrairhockey"
                text="VR Air Hockey"         
                />
      </div>
      </section>
      </div>      
      </div>
      );
}
 
export default Portfolio;