import Bachelor1 from '../Portfolio_Pictures/Attachment.jpg'
import Bachelor2 from '../Portfolio_Pictures/Attachment_with_controller.jpg'
import Bachelor3 from '../Portfolio_Pictures/doublePOV.jpg'

const AirHockey = () => {
    return (  
        <div className="about">
            <div className="aboutContent">
                <section className='contentBlock'>
                <div className="opaquebox">
                For our Bachelor project, we created an Augmented Virtuality Air Hockey game with multiplayer, to explore the passive haptic feedback of our own custom-made input device, functionally an augment for the original quest controller. 
                </div>
                <img src={Bachelor1} alt="Custom-made Attachment"/>
                </section>
                <section className='contentBlock'>
                <img src={Bachelor2} alt="Quest Controller with Custom-made Attachment" />
                <div className="opaquebox">
                We wanted the game to be playable anywhere with an appropriate surface, however it could also be played without any surface, but we felt that was against the spirit of the game, and found it took away part of the fun. 
                </div>
                </section>
                <section className='contentBlock'>
                <div className="opaquebox">
                The final game could be played remotely with another player and kept track of the score. The custom controller moved smoothly on surfaces, similar to a real air hockey paddle.
                 The game was developed in Unity, scripting in C#, and the multiplayer functionality through Photon 2.  
                </div>
                <img src={Bachelor3} alt="POV from both players in-game" />
                </section>
            </div>
        </div>
    );
}
 
export default AirHockey;