import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DropdownMenu from "./DropdownMenu";
import { useState } from "react";

function Navbar(){
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const handleMouseEnter = () => {
      setDropdownVisible(true);
    };
  
    const handleMouseLeave = () => {
      setDropdownVisible(false);
    };
    return (
        <nav className="navbar">
            <h1>Mark Sløk Tietze</h1>
            <div className="sections">
                <Link to ="/">Home</Link>
                 <div  className="menu"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave} >
                    <Link to="/portfolio">Portfolio</Link>
                    {isDropdownVisible && <DropdownMenu />}
                </div>
                
                <Link to ="/about">About Me</Link>
                </div>
        </nav>
      );
}
 
export default Navbar;
