import EcoSphere1 from '../Portfolio_Pictures/EcoSphere.jpg'

const EcoSphere = () => {
    return (  
       <div className="about">
        <div className="aboutContent">
            <section className='contentBlock'>
            <div className="opaquebox">
            The EcoSphere was our prototype for the Shape Changing Objects and Spaces.
             The course was done while cooperating with students taking the Social Robotics course, which is why it isn’t simply a shape changing object, but a social robot.
            </div>
            <img src={EcoSphere1} alt="A view of the inflated EcoSphere and an individual component" />
            </section>
            <div className="opaquebox">
            EcoSphere is a shape-changing robot designed to foster relaxed and engaging socratic dialogues on climate change topics.
            It has four pads that it can use to express its inner workings through the users’ hands. These pads can be inflated in different ways to form various patterns, by using varying speeds or intervals to convey the robots’ thought processes and activities.
            Instead of having a face or another visual representation for the robot, its presence is indicated through touch and voice only. 
            </div>
        </div>
        </div>
    );
}
 
export default EcoSphere;