import { Link } from "react-router-dom/cjs/react-router-dom.min";

const NotFound = () => {
    return (
        <div className="fourofourBackground">
        <div className="homeContent"> 
        <div className="opaquebox">
        <div className="not-found">
            <h2>404 <br></br>Page Not Found</h2>
            <p>Sorry, that page does not seem to exist</p>
            <Link to="/">Click here to return to the frontpage, <br/> or navigate using the navbar...</Link>
        </div>
        </div>
        </div> </div>
    );
}
export default NotFound;
