import './App.css';
import Navbar from './Navbar';
import Home from './Home';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Portfolio from './Portfolio pages/Portfolio';
import NotFound from './NotFound';
import About from './Portfolio pages/About';
import EcoSphere from './Portfolio pages/EcoSphere';
import NuclearReactor from './Portfolio pages/NuclearReactor';
import AirHockey from './Portfolio pages/ARAirHockey';
import Starsign from './Portfolio pages/Starsign';


function App() {
  return (
    <Router>
    <div className="App">
      <Navbar />
      <div class="backgroundContainer"></div>
      <div className="content">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/portfolio">
            <Portfolio />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route exact path="/portfolio/starsign">
            <Starsign />
          </Route>
          <Route path="/portfolio/nuclearreactor">
            <NuclearReactor />
          </Route>
          <Route path="/portfolio/ecosphere">
            <EcoSphere />
          </Route>
          <Route path="/portfolio/vrairhockey">
            <AirHockey />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    </div>
    </Router>
  );
}

export default App;
