import Nuclear1 from '../Portfolio_Pictures/ToyNuclear.jpg'
import Nuclear2 from '../Portfolio_Pictures/ToyExplodedView.png'

const NuclearReactor = () => {
    return (  
        <div className="about">
            <div className="aboutContent">
                <section className='contentBlock'>
                <div className="opaquebox">
                During the course Physical Computing we had to create a toy prototype.
                 We decided to create a Nuclear Reactor toy, inspired by the famous games Buckaroo and Operation. 
                    The goal of the toy is to light up the streetlights without exploding the city.
                By adding too many ‘radioactive’ components a meltdown is triggered, and the streetlights will flash red instead, and after a few seconds the city ‘explodes’, knocking the houses out of their slots.
                </div>
                <img src={Nuclear1} alt="The toy nuclear reactor with houses inserted" />
                </section>
                <section className='contentBlock'>
                <img src={Nuclear2} alt="Exploded view of the toy, showing most components" />
                <div className="opaquebox">
                    The prototype had two different inputs, adding cooling water using the knob, and adding ‘radioactive’ materials into the cooling tower. 
                    The number of materials inserted is calculated using a weight at the bottom of the tower.
                </div>
                </section>
            </div>
        </div>
    );
}
 
export default NuclearReactor;