import myPic1 from '../Portfolio_Pictures/HQcv.jpg'

const About = () => {
    return (
            <div class="about">
                <div class="aboutContent">
                    <section className='contentBlock'>
                <div class="opaquebox">
                    <p>
                    I have just completed my Bachelor's Degree in IT Product Development, but I still want to learn more, especially regarding programming.
                     I have experience with Agile Development, namely using Scrum.
                     I quickly pick up new programs and pay attention to the small details, regardless of whether it’s related to code or customer correspondence. During my Bachelor’s Degree, I have primarily worked with object-oriented languages, such as C# and Java, but I also have experience with JavaScript and Python.
           </p>
           
           </div>
           <img src={myPic1} alt="CV picture" />
                </section>
                <div class="opaquebox">
                    <p>
                    In my spare time, I play board games and video games with friends. During my time at the university, I have helped with the organisation of parties and other events for my year group. In addition, I like to spend time reading books and comics. 
                    I also occasionally go fishing. The dogs in the pictures are my parents' dogs, the mischiveous light fur Frigg and the mature, older and dark fur Jolly.
                     </p>
                 </div>
                 </div>
                 </div>
        );
}
 
export default About;